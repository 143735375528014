import Vue from 'vue'
import LocationIcon from './LocationIcon'
import LocationMiniIcon from './LocationMiniIcon'

// create a constructor from a Vue component 
const LocationIconConstructor = Vue.extend(LocationIcon)
const LocationMiniIconConstructor = Vue.extend(LocationMiniIcon)

export const getIconUrl = (markerColor, statusColor, typeIcon = 'default') => {
  // create a Vue element with required props
  const iconComponent = typeIcon === 'mini' ? new LocationMiniIconConstructor({propsData: { markerColor, statusColor }}) : new LocationIconConstructor({propsData: { markerColor, statusColor }})
  // mount the component shadow DOM
  iconComponent.$mount()
  // get icon DOM element
  const iconDom = iconComponent.$el
  // generate an html string from an element
  const iconString = new XMLSerializer().serializeToString(iconComponent.$el)
  // finally, generate a data url from a string
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}
<template>
  <v-row no-gutters> 
    <v-col cols="12" class="grey lighten-5">
      <v-row class="px-5 py-3"> 
        <v-col cols="12" sm="7" md="9" class="d-flex align-center">
          <v-icon left>mdi-map-marker-radius-outline</v-icon>
          <span class="text-h4 font-weight-medium mr-3">Ruta del día: </span>
          <span class="text-h4 text-italic font-weight-bold mr-2 primary--text">
            {{date_route | smartDate}}
          </span>
        </v-col>
        <v-col cols="12" sm="5" md="3" class="d-flex align-center">
          <v-menu
            ref="menu1"
            v-model="date_menu_route"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :nudge-left="55"
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_route"
                hint="Seleccione fecha"
                persistent-hint
                class="theme-custom rounded-lg"
                filled
                dense
                hide-details
                readonly
                v-bind="attrs"
                @blur="date_calendar_route = parseDate(date_route)"
                v-on="on"
              >
                <template v-slot:append>
                  <v-icon v-on="on">mdi-chevron-down</v-icon>
                </template>
                <template v-slot:prepend-inner>
                  <v-icon v-on="on">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="date_calendar_route"
              no-title
              :max="max_date_route"
              @input="date_menu_route = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col> 
    <v-col cols="12" class="pa-4" v-if="ubications.length > 0">
      <v-row no-gutters>
        <v-col cols="12" md="8" :style="height_map">
          <view-maps :points="markers_route" :loadData="false" info/>
        </v-col>
        <v-col cols="12" md="4">
          <v-card dark flat tile color="primary lighten-2" :style="height_map" class="content-scroll">
            <v-card-text class="text-h5 font-weight-medium white--text px-0 py-2">
              <span class="pa-5">Clientes</span>
            </v-card-text>
            <v-card-text class="px-2">
              <v-list class="transparent py-0 px-0">
                <template v-for="(route_cli, i) in clientes">
                  <v-list-item class="item-cliente-route rounded-lg mb-3" :key="'routecli-'+i">
                    <v-list-item-icon class="mr-2">
                      <v-icon 
                        :color="route_cli.status_cuenta_vencida ? status.rojo : route_cli.status_cuenta_dolar ? status.amarillo : status.verde"
                        v-text="route_cli.lat != null ? 'mdi-map-marker' : 'mdi-map-marker-off'"            
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title 
                        style="white-space: normal !important" 
                        v-text="route_cli.cli_des + ' ('+ route_cli.co_cli + ')'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
     <v-col cols="12" class="pa-12 text-center" v-else>
       <v-avatar size="106" color="blue-grey lighten-5 d-block mx-auto mb-4">
         <v-icon size="60" color="blue-grey">mdi-map-marker-remove-variant</v-icon>
        </v-avatar>
       <span class="text-h4 blue-grey--text font-italic py-6">No se encontró una ruta para el día</span>
     </v-col>
    <v-overlay 
      :value="loading"
      color="white" 
      absolute
      opacity="1"
    >
      <div class="d-flex flex-column justify-center">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"  
          class="mx-6"        
        ></v-progress-circular> <br>
        <span class="primary--text py-5">Obteniendo Ruta</span>
      </div>
    </v-overlay>
  </v-row>
</template>
<script>
import moment from 'moment'
import { getIconUrl } from '@/widgets/iconGenerator'
import { get } from 'vuex-pathify'
export default {
  name: 'Rutas',
  components: { 
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
  },
  props:{
    ubications:{
      type: Array,
      default: () => ([])
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    date_route:  moment().format('YYYY-MM-DD'),
    date_calendar_route:  moment().format('YYYY-MM-DD'),
    date_menu_route: false,
    center: { lat: 8.955582, lng: -65.3220065 },
  }),

  watch: {
    date_calendar_route (val) {
      this.date_route = moment(val).format('YYYY-MM-DD');
      this.$emit('change', this.date_route);
    },
  },

  methods:{
    parseDate(date){
      return moment(date).format('YYYY-MM-DD');
    }
  },

  computed:{
    status: get('app/statusClient'),
    max_date_route(){
      return moment().format('YYYY-MM-DD');
    },
    markers_route(){
      return this.ubications.length > 0 ? this.ubications.filter(cli => cli != null && cli.lat != null).map(item => ({
        name: item.cli_des,
        rif: item.rif,
        position: {
          lat: parseFloat(item.lat.trim()),
          lng: parseFloat(item.lng.trim()),
        },
        icon:{
           markerColor: item.status_cuenta_vencida ? this.status.rojo : item.status_cuenta_dolar ? this.status.amarillo : this.status.verde,
          statusColor: '#000000',
        },
      })) : []
    },
    clientes(){
      return this.ubications.length > 0 ? this.ubications.filter(cli => cli != null ) : [];
    },
    height_map(){
      return {
        height: this.$vuetify.breakpoint.mobile ? '100vh' : '40rem',
        overflow: 'auto'
      }
    }
  },
}
</script>
<style lang="sass" scoped>
.item-cliente-route 
  background-color: rgba(255,255,255,0.10) !important
  border-color: rgba(255,255,255,0.10) !important


</style>
<template>
<svg xmlns="http://www.w3.org/2000/svg" width="38" height="53.7">
  <path d="M19 12.8a6.2 6.2 0 106.2 6.2 6.2 6.2 0 00-6.2-6.2z" :fill="statusColor"/>
  <path d="M19 .5A18.5 18.5 0 003.2 28.7l14.7 23.7a1.5 1.5 0 002.6 0l14.4-23.9A18.5 18.5 0 0019 .5zm0 27.8a9.3 9.3 0 119.3-9.3 9.3 9.3 0 01-9.3 9.3z" stroke="#fff" stroke-miterlimit="10" :fill="markerColor"/>
</svg>

  <!-- <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <defs>
      <circle id="my-location-a" cx="14" cy="14" r="8" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <use :fill="markerColor" xlink:href="#my-location-a"/>
      <circle cx="14" cy="14" r="11" stroke="#666666" stroke-opacity="0.3" stroke-width="6" />
      <circle cx="14" cy="14" r="7" stroke="#FFF" stroke-linejoin="square" stroke-width="2" />
    </g>
  </svg> -->
</template>
<script>
export default {
  name: 'LocationIcon',
  props: ["markerColor", "statusColor"]
}
</script>
<template>
<!-- <svg xmlns="http://www.w3.org/2000/svg" width="38" height="53.7">
  <path d="M19 12.8a6.2 6.2 0 106.2 6.2 6.2 6.2 0 00-6.2-6.2z" :fill="statusColor"/>
  <path d="M19 .5A18.5 18.5 0 003.2 28.7l14.7 23.7a1.5 1.5 0 002.6 0l14.4-23.9A18.5 18.5 0 0019 .5zm0 27.8a9.3 9.3 0 119.3-9.3 9.3 9.3 0 01-9.3 9.3z" stroke="#fff" stroke-miterlimit="10" :fill="markerColor"/>
</svg> -->
<svg xmlns="http://www.w3.org/2000/svg" width="24.2" height="29.5" viewBox="0 0 19.2 24.5">
  <path 
    d="M10,2A9.3,9.3,0,0,0,.7,11.3c0,7.5,8.4,14.4,8.4,14.4a1.5,1.5,0,0,0,1.8,0s8.4-6.9,8.4-14.4A9.3,9.3,0,0,0,10,2Zm0,14a4.7,4.7,0,1,1,4.7-4.7A4.7,4.7,0,0,1,10,16Z" 
    transform="translate(-0.4 -1.7)" 
    :fill="markerColor" 
    stroke="#fff" 
    stroke-miterlimit="10" 
    stroke-width="0.52"
  />
  <circle cx="10" cy="10" r="5" :fill="statusColor"/>
</svg>
</template>
<script>
export default {
  name: 'LocationMiniIcon',
  props: ["markerColor", "statusColor"]
}
</script>